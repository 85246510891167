<template>
  <v-container fluid>
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          SUBIENDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <v-col
        cols="12"
        md="12"
        class="d-flex flex-column justify-space-between align-center"
      >
        <!-- <div
          class="d-flex flex-column justify-space-between align-center"
        >
          <v-img
            :src="imgLogo"
            max-width="100"
          />
        </div> -->
        <base-material-card
          width="80%"
          color="primary"
          icon="mdi-handcuffs"
          title="Registro de producción de juzgado de paz"
          max-width="800"
        >
          <span
            class="text-caption grey--text"
          >
            <v-icon>mdi-account-tie</v-icon> Juez: {{ dataUsuario.juez }} <br><v-icon>mdi-gavel</v-icon> Juzgado: {{ dataUsuario.nombre }}<br><v-icon>mdi-map-marker</v-icon> Ubicación:  {{ dataUsuario.direccion }} - {{ dataUsuario.distrito }} - {{ dataUsuario.provincia }} - {{ dataUsuario.departamento }}
          </span>

          <br>
          <v-card-title>
            <v-row justify="center">
              <v-col
                cols="12"
                md="4"
              >
                <v-file-input
                  v-model="archivoCsv"
                  :rules="reglaUpload"
                  show-size
                  placeholder="Adjunte el archivo de metas"
                  prepend-icon="mdi-microsoft-excel"
                  clear-icon
                  label="Adjunte el archivo de delitos"
                />
                <v-btn
                  small
                  dark
                  block
                  color="success"
                  @click="updateResumen(archivoCsv)"
                >
                  <v-icon left>
                    mdi-upload
                  </v-icon>
                  Subir
                </v-btn>
                <span class="text-caption grey--text font-weight-light mx-auto">
                  {{ textoUpdate }}</span>

                <br>
                <v-row justify="center">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      small
                      block
                      outlined
                      @click="descargarPlantilla()"
                    >
                      <v-icon
                        left
                        color="success"
                      >
                        mdi-file-excel
                      </v-icon>
                      DESCARGAR PLANTILLA
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <br>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import * as XLSX from 'xlsx'
  export default {
    name: 'Upload',
    data: () => ({
      reglaUpload: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          'El archivo no debe pesar más de 5 MB',
      ],
      usuario: '',
      nombreUsuario: '',
      mesUpdate: '',
      sedeCorteData: '',
      carga: false,
      archivoCsv: [],
      dataUpdate: [],
      urlDataSede: '',
      sumaI: 0,
      incorrectos: 0,
      imgLogo: '',
      textoUpdate: '',
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioOdajup'))
      if (!this.dataUsuario) {
        this.$router.replace('/login')
      } else {
        this.validarToken()
        this.imgLogo = 'assets/favicon.png'
      }
    },
    mounted () {
      this.$store.commit('SET_VALOR', false)
    },
    methods: {
      cerrarSesion () {
        localStorage.setItem('usuarioOdajup', null)
        this.$router.push('/')
      },
      validarToken () {
        const headers = { headers: { 'access-token': localStorage.getItem('tokenOdajup') } }
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/token`, headers)
          .then((r) => {
            if (r.data.message !== true) {
              this.$router.replace('/cerrar')
            } else {
              this.carga = false
            }
          })
          .catch((err) => {
            alert('Servicio no disponible' + err)
            this.carga = false
            this.$router.replace('/cerrar')
          })
      },
      groupBy (keys) {
        return function (array) {
          return array.reduce(function (objectsByKeyValue, obj) {
            var value = keys.map(function (key) { return (obj[key]) }).join('-')
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
            return objectsByKeyValue
          }, {})
        }
      },
      descargarPlantilla () {
        if (this.dataUsuario.tipo_usuario !== 'J') {
          window.location.assign('./assets/OdajupPlantillaEstadistica.xlsx')
        } else {
          window.location.assign('./assets/OdajupPlantillaJuez.xlsx')
        }
      },
      updateProduccion (item, fin, nro) {
        console.log(item.resueltos_conciliacion)
        if (!item.id_tipo_competencia || !item.mes || !item.ano || !item.ingreso) {
          alert('La fila ' + nro + ' no tiene todos los campos, por favor completar los campos obligatorios como id_tipo_competencia, mes, ano, ingreso, admision')
          this.incorrectos++
          if (this.incorrectos === parseInt(fin)) {
            alert('LAS ' + fin + ' FILAS DEBEN CORREGIRSE')
            this.carga = false
          }
        } else {
          const headers = { headers: { 'access-token': localStorage.getItem('tokenOdajup') } }
          let data = {}
          if (this.dataUsuario.tipo_usuario === 'J') {
            data = {
              id_juzgado: this.dataUsuario.id_juzgado,
              id_tipo_competencia: item.id_tipo_competencia,
              mes: item.mes,
              ano: item.ano,
              ingreso: item.ingreso,
              resueltos_conciliacion: item.resueltos_conciliacion,
              resueltos_sentencia: item.resueltos_sentencia,
              medidas_proteccion: item.medidas_proteccion,
              medidas_cautelares: item.medidas_cautelares,
              conciliacion_sin_acuerdo: item.conciliacion_sin_acuerdo,
              conciliacion_acuerdo_parcial: item.conciliacion_acuerdo_parcial,
              conciliacion_acuerdo_total: item.conciliacion_acuerdo_total,
              atendidos: item.atendidos,
              denegados: item.denegados,
              concluidos: item.concluidos,
              reembolso: item.reembolso,
              id_usuario: this.dataUsuario.id_usuario,
            }
          } else {
            data = {
              id_juzgado: item.id_juzgado,
              id_tipo_competencia: item.id_tipo_competencia,
              mes: item.mes,
              ano: item.ano,
              ingreso: item.ingreso,
              resueltos_conciliacion: item.resueltos_conciliacion,
              resueltos_sentencia: item.resueltos_sentencia,
              medidas_proteccion: item.medidas_proteccion,
              medidas_cautelares: item.medidas_cautelares,
              conciliacion_sin_acuerdo: item.conciliacion_sin_acuerdo,
              conciliacion_acuerdo_parcial: item.conciliacion_acuerdo_parcial,
              conciliacion_acuerdo_total: item.conciliacion_acuerdo_total,
              atendidos: item.atendidos,
              denegados: item.denegados,
              concluidos: item.concluidos,
              reembolso: item.reembolso,
              id_usuario: this.dataUsuario.id_usuario,
            }
          }
          console.log(data)
          axios
            .post(`${localStorage.getItem('urlApiOdajup')}v0/registro`, data, headers)
            .then((r) => {
              console.log(r)
              this.sumaI++
              console.log(this.sumaI) // eslint-disable-line
              console.log(this.incorrectos) // eslint-disable-line
              console.log(fin) // eslint-disable-line
              if (this.sumaI === (parseInt(fin) - this.incorrectos)) {
                this.carga = false
                alert('INFORMACIÓN DEL ARCHIVO SUBIDA, SE SUBIERON ' + this.sumaI + ' REGISTROS DE DELITOS, HUBIERON ' + this.incorrectos + ' FILAS INCOMPLETAS')
                this.archivoCsv = []
                this.incorrectos = 0
                this.sumaI = 0
              }

            // this.$router.go()
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
            })
        }
      },
      updateResumen (e) {
        var reader = new FileReader()
        var arrayJuzgados = []
        var self = this
        reader.onload = function (e) {
          var data = new Uint8Array(e.target.result)
          var workbook = XLSX.read(data, { type: 'array' })
          const hoja = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[hoja]
          arrayJuzgados = XLSX.utils.sheet_to_json(worksheet)
          for (var i = 0; i < arrayJuzgados.length; i++) {
            self.carga = true
            self.updateProduccion(arrayJuzgados[i], arrayJuzgados.length, i)
          }
        }
        reader.readAsArrayBuffer(e)
      },
    },
  }
</script>
